import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Style API`}</h1>
    <p>{`A component style `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Application_programming_interface"
      }}>{`API`}</a>{`
is a concept where a team defines what props can be passed to a component. These props
are what determine the style variations a component might have.`}</p>
    <p>{`For example, a button might accept two props, `}<inlineCode parentName="p">{`variant`}</inlineCode>{` and `}<inlineCode parentName="p">{`size`}</inlineCode>{`, where `}<inlineCode parentName="p">{`variant`}</inlineCode>{`
might change the color (danger, success, primary, link) and `}<inlineCode parentName="p">{`size`}</inlineCode>{` will affect the
font size and padding.`}</p>
    <p>{`This information can be passed to a component which can compute the
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Cartesian_product"
      }}>{`cartesian product`}</a>{` and
display all combinations of the props.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Cartesian
  component={Button}
  props={{
    variant: ['danger', 'success', 'primary', 'link'],
    size: ['sm', 'md', 'lg', 'xl'],
    children: 'Hello, world!'
  }}
/>
`}</code></pre>
    <p>{`This is important because it provides a bird's eye view to designers and developers to
see if a component has problematic states. With `}<inlineCode parentName="p">{`compai`}</inlineCode>{`, you can define a style API
so that you can ensure generated outputs fall within your constraints.`}</p>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://mrmrs.cc/writing/component-styling-api/"
        }}>{`Component Styling API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/c8r/kit#cartesian"
        }}>{`Cartesian Component`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      